import React, { useRef, useState, useEffect } from 'react';
import {
  Banner,
  BillTracking,
  DebitExplain,
  Features,
  CashBack,
  TopBar
} from '@components/LandingPage';
import Footer from '@components/Footer';

const LandingPage = () => {
  const [prevScrollPosition, setPrevScrollPosition] = useState(0);
  const [visible, setVisible] = useState(false);
  const cashBackRef = useRef(null);
  const billTrackingRef = useRef(null);
  const debitFeaturesRef = useRef(null);
  const debitExplainRef = useRef(null);

  const handleScroll = () => {
    const currentScrollPosition = window.scrollY;

    if (currentScrollPosition > prevScrollPosition) {
      setVisible(true);
    } else {
      setVisible(false);
    }
    setPrevScrollPosition(currentScrollPosition);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <>
      <div className="w-screen min-h-full m-auto flex flex-col">
        <div className="w-full m-auto">
          {visible && <TopBar />}
          <Banner />
          <section ref={debitExplainRef} id="debit_explain">
            <DebitExplain
              cashBackRef={cashBackRef}
              billTrackingRef={billTrackingRef}
              debitFeaturesRef={debitFeaturesRef}
            />
          </section>

          <section ref={cashBackRef} id="cash_back">
            <CashBack />
          </section>

          <section ref={billTrackingRef} id="bill_tracking">
            <BillTracking />
          </section>

          <section ref={debitFeaturesRef} id="debit_features">
            <Features />
          </section>

          <div className="w-full mb-4 desktop:mb-0 h-full desktop:mr-4 px-6 desktop:px-12 xl:px-44 py-10 desktop:py-8">
            <div className="flex flex-col justify-around items-center xl:gap-8 h-full">
              <div className="hidden desktop:flex h-1/6 m-auto">
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
