import React, { useRef } from 'react';
import { CardAndAppImg, TFSRewardsLearnMoreImg } from '@constants/images';
import IconWrapper from '@components/IconWrapper/IconWrapper';
import PiggyBankIcon from './Icons/piggybank.json';

const CashBack = () => {
  const PiggyBankIconRef = useRef(null);
  return (
    <div
      className="bg-cover bg-center py-8 px-4 lg:px-16"
      style={{
        backgroundImage: `url(${TFSRewardsLearnMoreImg})`
      }}>
      <div className="max-w-6xl mx-auto bg-white bg-opacity-80 rounded-lg shadow-lg p-8 ">
        <div className="flex flex-col md:flex-row items-center">
          <div className="flex flex-col text-gray-800 md:flex-1">
            <h1 className="text-2xl md:text-4xl font-bold mb-4">
              Get Rewarded for Everyday Shopping
            </h1>
            <p className="text-lg md:text-xl mb-6">
              Maximize your existing spending needs by turning everyday shopping into cash-back*
              contributions toward your Chapter 13.
            </p>
            <p className="text-lg md:text-xl mb-6">
              As a TFS user, we are excited to offer an exclusive benefit that aligns perfectly with
              your Chapter 13 goals. Purchases at over 10,000 merchants will bring you one step
              closer to completing your plan payments by depositing cash-back into your YourSuccess
              Bank Account.
            </p>
            <p className="text-lg md:text-xl mb-6">
              You made the wise financial choice to file Chapter 13. Now decide that every dollar
              counts. Start spending smart and reaping the benefits of this exclusive cash-back reward.
            </p>
            <a
              href="https://tfsbillpay.com/tfs_banking/signup"
              target="_blank"
              rel="noreferrer noopener">
              <input
                type="button"
                value={'Register Now'}
                className="mt-4 bg-greyblue hover:bg-greyblueHover text-white font-semibold rounded-full transition duration-300 tablet:w-[315px] desktop:w-[275px] h-14 cursor-pointer disabled:cursor-not-allowed disabled:opacity-50"
              />
            </a>
          </div>
          <div className="mt-8 md:mt-0 w-full md:w-1/2 flex justify-center">
            <IconWrapper icon={PiggyBankIcon} size={256} reference={PiggyBankIconRef} />
          </div>
        </div>
        <div className="italic text-xs mt-7 text-center">
          *This optional Cash-Back Rewards program is not a Pathward product or service, nor is it
          endorsed or supported by Pathward in any way.
        </div>
      </div>
    </div>
  );
};

export default CashBack;
